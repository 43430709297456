import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Going Down The Funnel`}</h1>
    <p><strong parentName="p">{`Shaan’s Weekly Note:`}</strong></p>
    <p>{`(SPOILER Alert) - if you follow me on twitter, you probably saw - we crushed our $1M goal and are already at $1.7M raised for the fund.`}</p>
    <p>{`So - what now?`}</p>
    <p>{`First - I'm going to show you how we did it. The world just sees the tip of the iceberg.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "502px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "114.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQCBQP/xAAYAQADAQEAAAAAAAAAAAAAAAACAwQAAf/aAAwDAQACEAMQAAABzfytVT0uYZvOgScQPv8A/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxIREzH/2gAIAQEAAQUCraYUDsUSnIrfyLblnsGOZ//EABcRAQEBAQAAAAAAAAAAAAAAAAEQAiH/2gAIAQMBAT8BWaOz/8QAFxEBAQEBAAAAAAAAAAAAAAAAAAEREv/aAAgBAgEBPwHHCVtf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAEEECUXH/2gAIAQEABj8CQApHkIXpvF5H3X//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMVGx/9oACAEBAAE/IRW8tiA5f2LtA7ssQ1Tkx6GKciaCDpSYCvBVRbP/2gAMAwEAAgADAAAAECTnff/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QU5nLYEWF/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIRD/2gAIAQIBAT8Q02ZCxcYOX//EAB4QAQADAQACAwEAAAAAAAAAAAEAESExQVFhkaHR/9oACAEBAAE/EELhKXzvlYRONjbS/kqgZAZv1KolY0UDh+xGODELgKVWFMK3kAsT3zvzBmw0o7e3Igq67P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Tip of the Iceberg",
          "title": "Tip of the Iceberg",
          "src": "/static/2993e4c02f783a6e2bdbeef66193f6fb/3e0a9/tip-of-iceberg.jpg",
          "srcSet": ["/static/2993e4c02f783a6e2bdbeef66193f6fb/a6407/tip-of-iceberg.jpg 400w", "/static/2993e4c02f783a6e2bdbeef66193f6fb/3e0a9/tip-of-iceberg.jpg 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Second - we're going to stop fundraising, and set up our systems to run the fund effectively (keyword, systems). Then we move onto venture #2 - launching an education/course that will pull in $1m+.`}</p>
    <p><strong parentName="p">{`Now, for today’s letter….`}</strong>{`  `}</p>
    <p>{`Yesterday was all about trying to close hot leads, today is all about figuring out how to go further down the funnel to warm & cold leads.`}</p>
    <p><strong parentName="p">{`A quick reminder:`}</strong></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Hot lead`}</strong>{` == someone who knows me, and proactively asked to invest == easy`}</li>
      <li parentName="ul"><strong parentName="li">{`Warm lead`}</strong>{` == someone who knows me, but has not showed interest in investing == medium`}</li>
      <li parentName="ul"><strong parentName="li">{`Cold lead`}</strong>{` == doesn't know me. We've never talked == hard.`}</li>
    </ul>
    <p><strong parentName="p">{`Warm Leads:`}</strong>{` `}<br />{` I’m going to let you in on the secret to raising warm money, without creating awkward situations.`}</p>
    <p><strong parentName="p">{`"Ask for advice, not money"`}</strong></p>
    <p>{`That's right. We're going to go to our acquaintances, and asking them if they think this pitch is going to be effective at raising money. This is a triple-word-score:`}</p>
    <ol>
      <li parentName="ol">{`They might give you good feedback, which helps future pitches`}</li>
      <li parentName="ol">{`They will hear your pitch, in a low pressure situation. Not interested? No problem. This wasn't a sales pitch, you don't have to awkwardly reject me.`}</li>
      <li parentName="ol">{`But if they are interested, it gives them a natural opening to say "btw, I'd be interested in being a part of this"`}</li>
    </ol>
    <p>{`I want my emails to be like Brad Pitt's hair. It should look casual & slightly messy, but actually it's been meticulously prepared to be attractive.`}</p>
    <p>{`Let me show you how I do this. Here's one of my emails, with a line-by-line breakdown of why each sentence is the way it is.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://docsend.com/view/tahdbsjp3c5y6c2d"
        }}>{`Click here for the line-by-line breakdown`}</a></strong></p>
    <p>{`Getting this right takes some precision - every single line matters. Every word matters. Make it count.`}</p>
    <p>{`This approach works because it’s flattering, simple, and low-pressure - asking for feedback instead of asking for money.`}</p>
    <p>{`And that my little blush - is a deadly combination.`}</p>
    <p>{`I sent about 40 of those emails out. I'm going to wait for replies (is the email working?) before sending to another 60 or so people.`}</p>
    <p><strong parentName="p">{`Cold Leads:`}</strong><br parentName="p"></br>{`
`}{`While I was getting emails ready to send to the warm leads, I told my main man Ben to figure out how to get more leads. Divide and conquer. I'll pitch the current leads, while he goes to get new ones. So who is our cold audience?`}</p>
    <p>{`The key is to find strangers with something in common. I brainstormed who this might be:`}</p>
    <p>{`Maybe people who have the same job as me (eg. other founders? People working in tech companies?)`}</p>
    <p>{`Then I figured it out. College alumni.`}</p>
    <p>{`For some strange reason, people have a huge affinity to their college. Especially successful people. They are super willing to talk to a stranger who went to the same school as them. Maybe it's nostalgia, they miss the simpler times when beer pong was their top priority. I don't know why, but it works.`}</p>
    <p>{`Duke has 7,000 students a year...which means that’s an audience of nearly 300,000+ alumni who are working professionals.`}</p>
    <p>{`So I asked Ben to get me a list of "2,000 rich people who went to duke".`}</p>
    <p>{`After all, ya boi still has his 2010 Duke Startup Challenge jumbo check somewhere in self-storage.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "842px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "96.50000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEkklEQVQ4ywGHBHj7AOzy++3v9NXW2a+wsn1/f09QUC4uLxgYGRoZGSEhJSQrLiYuMisxNjYzODIqMDI1NSo2LpeZmP3//+Hm8QBNTEk+PDkdGhcJBwMKCQYODg0ZGxwkKy4kLzMgMjUhLjIbMDA0S0aCjIeIlpCDopVwinx7fXf////m6/QABwMABQMADw0KHR0dIiQnKCsxKjA1IywxQ0VKY1deYHBrf5GLbKyWTbGQSKuJU6GGcXRwS2ZY8u/z6e/4AAwKCQsJCBMRCx4bHCEgJCs7ODVXTj56aFujioe6qYSwn4O3pG+plk6liEmdgkyMdld9bi9XRdnV2e3z/AAKCAYJCAUPCgYnOzI6dWREjHhPoodZu5tYuJlLupdwno1wmotMtZNTqo5MnIJCjXM9k3krX0yura/0+f8ACwkGCwoICwICLlZERpyHRZGCT6CIVq6QWK+TWLeYaKeRs2h+XK2SVKyQUaaLSZmARpyDJWtThYeI+Pz/AAsJBwsKCAkDAiQ5LEifh0mgiU6li0+ojlSoj1WukUyYfllNQFmljVavlU2iiEqXfjJnVBlMN1tsaezu+AALCQYLCQcLBgUbIhlAm4JMk35QmIBokXdcoYdippdmpI57XUnCsJeKg25Ud2JXooYmSzwHDAclMTDg5O4AEA4LCAYEBggHEw0JOV9NWGdSXmJLj3xhl4tvtJyGqIFknoJu47mhfEMvfz4mTWlVECEdAwAABAUIwMXPABoVEgcAAFwvHSYSDFg0JUdFM4plStO0mdGgibl9WIBWTmBTZtGojYZgTGgvGi4fGRwWEhIQDAAAAJueowA3JyQfCACNUTZCKh54YlxYQzW9kWn5zKzfuJ6th3NuY35EQoFrVk1ZQzYeFhEtIhsYEgwXEw8AAAB9fHsAYVRSORwSUjAfakkxjnNqYFBIeEks0Z+Bw6SMjFpDUz9KQDhdFRUmAwQBNSUeMiQdEAwKEg8LAwAARERFAJ2KhFg3KSwTCVQ0IFhDOC8pJTkcEkYsIiQfGzcWD4hbQph2ZysoRQcHBCMZFDskGxQQDBIOCxkSDCIdGQDBtLF/Xk8sEAY1HhINCQcHBgUhFA8aEQ0NDAspFg+NYkiohXUxLFMODA0aEw0uHBYwIRY8KRhPNB9VNx8A3NfXrox7ThoIMBoSFQ8MEAwLHhIMFxIOEA0NRTAboHNNvJR8PjVYFREZIRsQSCQXhj4jakcnfFQvhlozAOrv9M+3qGAmDCMUDxAMChYRDEYzHBUSDQ4MCzQmGGVFJJVyVl5NYxsWIy8jE0wrG6FLLI5hNqd3Q59wPQDy+P/e1M1cMhwrGA8lGxMkGxFwTiklGRAPCwolGRGJXjG4hFJbS14dGS5cQSRtQSR5Nxyqdz67hEa8ilEA8Pj/+PXyvItaOB4SQycYYTsggVUufVMweVMyrnlJ1JVX2ZhYlWxVrHhO1Zhby5lpu5d16sun7NrH5N7aAObv/e7y+taoeolTK59rPciKT9aXWdmaXeOhY+mmZuquduW1iujDoO7Xwezi2vDu7vf7/vX6/+vy/Oft+A6dnTFUHzuDAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shaan Duke Startup Challenge",
          "title": "Shaan Duke Startup Challenge",
          "src": "/static/124f840a7f7221b2e4577c5c97f2c300/26003/shaan-duke-startup-challenge.png",
          "srcSet": ["/static/124f840a7f7221b2e4577c5c97f2c300/d9f49/shaan-duke-startup-challenge.png 400w", "/static/124f840a7f7221b2e4577c5c97f2c300/78d47/shaan-duke-startup-challenge.png 800w", "/static/124f840a7f7221b2e4577c5c97f2c300/26003/shaan-duke-startup-challenge.png 842w"],
          "sizes": "(max-width: 842px) 100vw, 842px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`So let's let Ben outline how he did it:`}</p>
    <p>{` Yo. `}<a parentName="p" {...{
        "href": "https://twitter.com/benmlevy"
      }}>{`Ben here`}</a>{`. Pleasure to meet ya.`}</p>
    <p>{`Shaan asked me to find these cold leads, and tbh I've never done this before. So I started browsing linkedin, and asked myself - who has money?`}</p>
    <p>{`Then it hit me - it’s all the big sectors - Commercial Real Estate, Private Equity, Investment Banking, Tech, Consulting, or Law.`}</p>
    <p>{`Still, those LinkedIn search results were a bit too broad.`}</p>
    <p>{`So, we tightened it even more - who went to Duke, graduated at least 5 years ago, and has a VP & above title?`}</p>
    <p>{`Now, we’re getting there.`}</p>
    <p>{`Search terms like “Commercial Real estate” on LinkedIn aren’t exactly great.`}</p>
    <p>{`So, we did research on the biggest companies in each of these spaces, since this would validate that these people are senior at a company that we know is successful.`}</p>
    <p>{`Fortunately, LinkedIn has been offering me a free premium trial for years (I’ve ignored this notification 100+ times). Now’s the time to use it!`}</p>
    <p>{`So I search Duke alumni that graduated 5+ years ago, have a VP+ title, and work at CBRE.`}</p>
    <p>{`Boom - only 15 results.`}</p>
    <p>{`Rinse and repeat this across the biggest companies in each sector and we have our list of the right targets.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "668px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "9.499999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAYElEQVQI1yWMSQ7DMAwD8/9HtmhiW6slWU7uEVBgDgMS5NEGIrFlcFjsjJVtwABCFiBWCzUHZEBCkkKne6w/R6+pKOX86CW37fv5XR1YZHq9TI/y71kRlp+tYxVr+8riBUbXbkDNhRo3AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ben Fundraising Sheet",
          "title": "Ben Fundraising Sheet",
          "src": "/static/6dacf589ce86578f082c21700b239b28/1d36f/ben-linked-in-fundraising-sheet.png",
          "srcSet": ["/static/6dacf589ce86578f082c21700b239b28/d9f49/ben-linked-in-fundraising-sheet.png 400w", "/static/6dacf589ce86578f082c21700b239b28/1d36f/ben-linked-in-fundraising-sheet.png 668w"],
          "sizes": "(max-width: 668px) 100vw, 668px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Finding Emails for Our Cold Leads:`}</strong><br parentName="p"></br>{`
`}{`Then, we found tools to scrape LinkedIn (using this cool product called `}<a parentName="p" {...{
        "href": "https://wiza.co/"
      }}>{`Wiza`}</a>{` that we found). Basically, you plug in a search (Duke alum + graduated before 2015 + VP+ title + works at a big co) and this tool will grab every person’s email that fits our criteria. `}</p>
    <p><strong parentName="p">{`Now We Have a List of 500 cold targets:`}</strong><br parentName="p"></br>{`
`}{`Two hours, a free LinkedIn trial, and $45 later, we’ve run tons of searches and have a spreadsheet of 500+ people who we know are most likely qualified to be an investor and are Duke alumni.`}</p>
    <p>{`Boom - we have our list of cold emails.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.youtube.com/watch?v=h4UqMyldS7Q"
        }}>{`Today was a good day`}</a>{`:`}</strong></p>
    <p>{`We fired bullets at warm leads...and should see some replies tonight. And we grabbed a huge list of cold leads - in case we need to go that route (Because we're going to hit our goal, one way or another).`}</p>
    <p>{`Tomorrow, I'll show you what happens when the wave hits. And you’ve just gotta ride it and forget everything else.`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docsend.com/view/tahdbsjp3c5y6c2d',
        label: 'Pitch Email Line-by-Line Breakdown'
      }]
    }, {
      title: LinkLabel.Web,
      links: [{
        url: 'https://twitter.com/benmlevy',
        label: "Ben's Twitter"
      }, {
        url: 'https://www.youtube.com/watch?v=h4UqMyldS7Q',
        label: "Ice Cube - It Was A Good Day"
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.the_taste_of_rejection.label,
      slug: slugify(postMap.the_taste_of_rejection.slug)
    }} nextButton={{
      text: postMap.how_1_tweet_turned_into_1_5_m.label,
      slug: slugify(postMap.how_1_tweet_turned_into_1_5_m.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      